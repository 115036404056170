import { useState } from "react";
import { NavLink } from "react-router-dom";
import translation from "../../Translations/translation.json";
import "./ViewPositions.css";
import { useLanguage } from "../../LanguageContext";

const ViewPositions = () => {
  const [selectedVersion, setSelectedVersion] = useState("v2");

  // const handleVersionChange = (version) => {
  //   setSelectedVersion(version);
  // };
  const { toggleLanguage, language } = useLanguage();

  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  return (
    <div className="section-bg-pages section-bg view-positions-pg view-positions-pg-height">
      <div className="gradient-bg-circle-2"> </div>
      <div className="gradient-bg-circle"></div>
      <div className="container">
        <div className="page-width-vp">
          <div className="vp-pg">
            <div className="view-positions-heading">
              <h2>{translate("viewPositions.heading")}</h2>
              <div className="dropdown">
                <button
                  // className="btn dropdown-btn-pool dropdown-toggle"
                  className="btn dropdown-btn-pool "
                  type="button"
                  // data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedVersion}
                </button>
                {/* <ul className="dropdown-menu">
                  <li>
                    <button className="dropdown-item">v2</button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleVersionChange("v3")}
                    >
                      v3
                    </button>
                  </li>
                </ul> */}
              </div>
            </div>
            {selectedVersion === "v2" ? (
              <div className="navlink-a-btn-migrate">
                <NavLink to="/migrate" className="migrate-btn-a">
                  <button className="migrate-btn">
                    {translate("viewPositions.migrate")}
                    <i className="fa fa-angle-double-right fa-xs"></i>
                  </button>
                </NavLink>
                <NavLink to="/createPool">
                  <button className="new-positions-btn">
                    <i className="fa fa-plus me-1 fa-xs"></i>{" "}
                    {translate("viewPositions.newposition")}
                  </button>
                </NavLink>
              </div>
            ) : (
              <></>
            )}
          </div>{" "}
          <div className="selected-content">
            {selectedVersion === "v3" ? (
              <p>{translate("viewPositions.descriptionv3")}</p>
            ) : (
              <p>{translate("viewPositions.descriptionv2")}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPositions;
