import * as API from "../../Utils/Services/api";
import { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { ethers, Contract } from "ethers";
import translation from "../Translations/translation.json";
import PlaceholderLoading from "react-placeholder-loading";
import { toast } from "react-toastify";
import { swapContract } from "../../Ethereum/Constant/addresses";
import { swapABI, poolABI, ERC20ABI } from "../../Ethereum/Constant/abis";
import { contractInstance, signer } from "../../Ethereum/Connect/config";
import ethImg from "../../Assets/tinified/token-5.png";
import "./Banner.css";
import "../../App.css";
import { BrowserProvider } from "ethers";
import Loading from "../Loading/Loading";
import { useLanguage } from "../LanguageContext";

const logos = [
  {
    name: "POL",
    link: "/",
  },
  {
    name: "SHIB",
    link: "/",
  },
  {
    name: "USDP",
    link: "/",
  },
  {
    name: "SHIELDS",
    link: "/",
  },
  {
    name: "CRV",
    link: "/",
  },
  {
    name: "TUBBY",
    link: "/",
  },
  {
    name: "WBTC",
    link: "/",
  },
  {
    name: "ETH",
    link: "/",
  },
  {
    name: "USDC",
    link: "/",
  },
  {
    name: "MANA",
    link: "/",
  },
];
const Banner = () => {
  const [value, setValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [estimateTokenValue, setEstimateTokenValue] = useState("");
  const [estimateETHWValue, setEstimateETHWValue] = useState("");
  const [tokenData, setTokenData] = useState(null);
  const [isSwapped, setIsSwapped] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef(null);

  // new
  const [ethwBalance, setEthwBalance] = useState(null);
  const [balance, setBalance] = useState("");
  const [error, setError] = useState("");

  const fetchETHWBalance = async () => {
    try {
      if (!window.ethereum) {
        // setError("MetaMask is not installed!");
        setEthwBalance(null);
        return;
      }

      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (!accounts.length) {
        setEthwBalance(null);
        return;
      }

      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      const balance = await provider.getBalance(signer.address);
      const formattedBalance = parseFloat(ethers.formatEther(balance)).toFixed(
        6
      );
      setEthwBalance(formattedBalance);
      setError("");
    } catch (err) {
      console.error("Error fetching ETHW balance:", err.message);
      setError("Failed to fetch balance. Please try again.");
      setEthwBalance(null);
    }
  };

  const fetchTokenBalance = async () => {
    if (!window.ethereum || !selectedToken?.contractAddress) {
      setBalance("");
      return;
    }

    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (!accounts.length) {
        setBalance("");
        return;
      }

      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      // Create token contract instance
      const tokenContract = new ethers.Contract(
        selectedToken.contractAddress,
        [
          "function balanceOf(address account) view returns (uint256)",
          "function decimals() view returns (uint8)",
        ],
        provider
      );

      const rawBalance = await tokenContract.balanceOf(signer.address);
      const decimals = await tokenContract.decimals();
      setBalance(
        parseFloat(ethers.formatUnits(rawBalance, decimals)).toFixed(6)
      );
    } catch (err) {
      console.error(err);
      setBalance("Error fetching balance");
    }
  };
  useEffect(() => {
    const connectedWallet = localStorage.getItem("account");
    if (!connectedWallet) {
      setEthwBalance(null);
      setBalance("");
    } else {
      fetchETHWBalance();
      fetchTokenBalance();
    }
  }, [selectedToken]);
  // new close

  /// Estimate ETHW value from token input value
  const handleTokenInput = (e) => {
    // setValue(e.target.value.replace(/[^0-9]/g, ""));

    const value = e.target.value !== "" ? e.target.value : "0";
    setEstimateTokenValue(e.target.value);
    if (!selectedToken) return;

    // Clear previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set new debounce timeout
    debounceTimeout.current = setTimeout(async () => {
      try {
        const contract = await contractInstance(swapContract, swapABI);
        const poolAddress = await contract.getPair(
          selectedToken.contractAddress
        );

        if (poolAddress === "0x0000000000000000000000000000000000000000")
          return toast.info("Liquidity pool not exist");

        const poolContract = await contractInstance(poolAddress, poolABI);
        const expectedTokenOut = await poolContract.getSwapOutput(
          false,
          ethers.parseEther(String(value))
        );
        setEstimateETHWValue(ethers.formatEther(String(expectedTokenOut)));
      } catch (err) {
        if (err.reason === "Panic due to DIVIDE_BY_ZERO(18)")
          return toast.error("Liquidity pool not exist!");
        toast.error(err.reason);
      }
    }, 500);
  };

  /// Estimate Token value from ETHW input value
  const handleETHWInput = (e) => {
    // setValue(e.target.value.replace(/[^0-9]/g, ""));
    const value = e.target.value !== "" ? e.target.value : "0";
    setEstimateETHWValue(e.target.value);
    if (!selectedToken) return;

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(async () => {
      try {
        const contract = await contractInstance(swapContract, swapABI);
        const poolAddress = await contract.getPair(
          selectedToken.contractAddress
        );

        if (poolAddress === "0x0000000000000000000000000000000000000000")
          return toast.info("Liquidity pool not exist");

        const poolContract = await contractInstance(poolAddress, poolABI);
        const expectedTokenOut = await poolContract.getSwapOutput(
          true,
          ethers.parseEther(String(value))
        );
        setEstimateTokenValue(ethers.formatEther(String(expectedTokenOut)));
      } catch (err) {
        if (err.reason === "Panic due to DIVIDE_BY_ZERO(18)")
          return toast.error("Liquidity pool not exist!");
        toast.error(err.reason);
      }
    }, 500);
  };

  const swapDivs = () => {
    setIsSwapped(!isSwapped);
  };
  // useEffect(() => {
  //   (async () => {
  //     const data = {
  //       page: 1,
  //       size: 0,
  //       category,
  //       sortBy,
  //       sortDirection,
  //     };
  //     const resp = await API.GetRWACurrencies(data);
  //     setTokenData(resp.data.currency);
  //   })();
  // }, []);

  const getAllTokenDetail = async (filter = "") => {
    try {
      const data = {
        page: 1,
        size: 10,
        filter,
      };
      const resp = await API.GetAllToken(data);
      setTokenData(resp.data.allToken);
    } catch (err) {
      toast.error(err.response.data.message);
      setTokenData(null);
    }
  };
  useEffect(() => {
    getAllTokenDetail();
  }, []);

  // Fetch token data from contract
  // const selectHandler = async (e) => {
  //   try {
  //     const tokenContract = await contractInstance(e.target.value, ERC20ABI);
  //     const name = await tokenContract.name();
  //     const symbol = await tokenContract.symbol();

  //     if (name && symbol) {
  //       setTokenData([
  //         { image: "", name, symbol, contractAddress: e.target.value },
  //       ]);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // Fetch token data from powtoken database
  const selectHandler = (e) => {
    const filter = e.target.value;
    // Clear previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      getAllTokenDetail(filter);
    }, 1000);
  };

  const selectTokenHandler = (data) => {
    setSelectedToken(data);
  };

  const swapHandler = async () => {
    try {
      setLoading(true);
      const contract = await contractInstance(swapContract, swapABI);
      const poolAddress = await contract.getPair(selectedToken.contractAddress);
      const poolContract = await contractInstance(poolAddress, poolABI);

      if (isSwapped) {
        const userSigner = await signer();
        let provider = new BrowserProvider(window.ethereum);
        const tokenContract = await contractInstance(
          selectedToken.contractAddress,
          ERC20ABI
        );
        const tokenBalance = await tokenContract.balanceOf(userSigner);
        if (
          parseFloat(ethers.formatEther(String(tokenBalance))) <=
          parseFloat(estimateTokenValue)
        ) {
          setLoading(false);
          setEstimateETHWValue("");
          setEstimateTokenValue("");
          return toast.info("Insufficient balance");
        }
        const approveTx = await tokenContract.approve(
          poolAddress,
          ethers.parseEther(estimateTokenValue)
        );
        toast.info("Approve transaction sent! Waiting for confirmation...");
        await approveTx.wait();
        toast.success("Approval successfull!");

        toast.info("Please confirm again for the transaction in metamask!");
        const tx = await poolContract.swapTokens(
          false,
          ethers.parseEther(estimateTokenValue)
        );

        toast.info("Swapping transaction initialized");
        await tx.wait();

        const data = {
          from: userSigner.address,
          to: poolAddress,
          amountOfEthW: estimateETHWValue,
          amountOfToken: estimateTokenValue,
          transactionHash: tx.hash,
          poolAddress: poolAddress,
          tokenAddress: selectedToken.contractAddress,
          userAddress: userSigner.address,
          tradeType: false,
          tokenName: selectedToken.name,
        };

        const resp = await API.SwapToken(data);
        if (!resp.data.status) return toast.error(resp.data.message);

        toast.success("Swapped successful");
        setLoading(false);
        setShowModal(true);
        setEstimateETHWValue("");
        setEstimateTokenValue("");
      } else {
        const userSigner = await signer();
        let provider = new BrowserProvider(window.ethereum);
        const tokenBalance = await provider.getBalance(userSigner);
        setLoading(true);
        if (
          parseFloat(ethers.formatEther(String(tokenBalance))) <=
          parseFloat(estimateETHWValue)
        ) {
          setLoading(false);
          setEstimateETHWValue("");
          setEstimateTokenValue("");
          return toast.info("Insufficient balance");
        }
        const tx = await poolContract.swapTokens(
          true,
          ethers.parseEther(estimateETHWValue),
          {
            value: ethers.parseEther(estimateETHWValue),
          }
        );

        toast.info("Swapping transaction initialized");
        await tx.wait();

        const data = {
          from: poolAddress,
          to: userSigner.address,
          amountOfEthW: estimateETHWValue,
          amountOfToken: estimateTokenValue,
          transactionHash: tx.hash,
          poolAddress: poolAddress,
          tokenAddress: selectedToken.contractAddress,
          userAddress: userSigner.address,
          tradeType: true,
          tokenName: selectedToken.name,
        };

        const resp = await API.SwapToken(data);
        if (!resp.data.status) return toast.error(resp.data.message);
        toast.success("Swapped successful");
        setLoading(false);
        setShowModal(true);
        setEstimateETHWValue("");
        setEstimateTokenValue("");
      }
    } catch (err) {
      if (err.action) {
        toast.error(err.info.error.message);
      } else {
        toast.error(err.message);
      }
      // console.log("err", err.message);
      setLoading(false);
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const { toggleLanguage, language } = useLanguage();

  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  return (
    <div className="banner-bg section-bg">
      <div className="flex-bannerimg">
        {logos.map((logo, index) => (
          <NavLink to={logo.link} key={index}>
            <div className={`logo-img logo-${index + 1}`}>
              <div className="logo-info">{logo.name}</div>
            </div>
          </NavLink>
        ))}
      </div>
      <div className="main-div">
        <div className="div-table">
          <h1>
            {translate("banner.heading")} <br />
            {translate("banner.subHeading")}
          </h1>
          <div className="table-swap">
            {!isSwapped ? (
              <>
                <div className="swap-value mb-1">
                  <div className="grey-color">
                    {translate("banner.actions.sell")}
                  </div>
                  <div className="enter-val grey-color">
                    <div className="grey-color">
                      <input
                        type="text"
                        value={estimateETHWValue}
                        onChange={handleETHWInput}
                        placeholder="0"
                        id="number-input"
                        className="text-white "
                        autoComplete="off"
                        // disabled
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        flexDirection: "column",
                      }}
                    >
                      <button
                        type="button"
                        className="btn backdrop-btn-css"
                        // data-bs-toggle="modal"
                        // data-bs-target="#selectmodal1"
                      >
                        <div className="flex-class text-white ">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1733220306/B870CB730078368E_tedoei.webp"
                            alt=""
                            className="modal-img-btn"
                          />
                          ETHW
                          {/* <i className="fa fa-angle-down grey-clr"></i> */}
                        </div>
                      </button>
                      <div style={{ fontSize: "small", width: "7rem" }}>
                        {ethwBalance !== null ? (
                          <>{ethwBalance} ETHW </>
                        ) : (
                          <></>
                        )}

                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </div>
                      {/* <div
                      className="modal "
                      id="selectmodal1"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable text-white">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1
                              className="modal-title fs-5"
                              id="exampleModalLabel"
                            >
                              {translate("banner.actions.selectToken")}
                            </h1>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="suggestions">
                              <input
                                type="text"
                                name="userToken"
                                placeholder="Enter contract address"
                                className="form-control search-input-box search-input-box-2 pt-2 pb-2 mb-2"
                                // value={selectToken}
                                onChange={selectHandler}
                              />

                              {tokenData ? (
                                tokenData.map((suggestion, index) => (
                                  <div key={index} className="suggestion-item">
                                    <div className="suggestion-img">
                                      <img src={suggestion.image} alt="" />
                                    </div>
                                    <div className="suggestion-name">
                                      {suggestion.name}
                                    </div>
                                    <div className="suggestion-sym">
                                      ({suggestion.symbol.toUpperCase()})
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="suggestion-item">
                                  No results found
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="modal-footer get-started-btn ok-btn">
                            <button
                              type="button"
                              className="btn text-white "
                              data-bs-dismiss="modal"
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                  <div className="loading-div">
                    {/* <span className="grey-color">$1</span> */}
                    {/* <Loading /> */}
                    {/* <PlaceholderLoading shape="rect" width="30%" height={10} /> */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={swapDivs}
                >
                  <i
                    className="fa fa-arrow-down "
                    style={{
                      color: "#fff",
                      cursor: "pointer",
                      backgroundColor: " rgb(32, 32, 32)",
                      padding: "10px",
                      position: "absolute",
                      borderRadius: "10px",
                    }}
                  ></i>
                </div>
                <div className="swap-value mt-1">
                  <div className="grey-color">
                    {translate("banner.actions.buy")}
                  </div>
                  <div className="enter-val grey-color">
                    <div className="grey-color">
                      <input
                        type="text"
                        placeholder="0"
                        id="number-input"
                        value={estimateTokenValue}
                        className="text-white "
                        onChange={handleTokenInput}
                        autoComplete="off"
                      />
                    </div>
                    <div className="select-token-btn">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-modal-backdrop"
                          data-bs-toggle="modal"
                          data-bs-target="#selecttoken2"
                        >
                          <div
                            className="flex-class text-white "
                            style={{ textWrap: "nowrap" }}
                          >
                            {selectedToken ? (
                              <>
                                <img
                                  src={selectedToken.imageLogo}
                                  alt=""
                                  className="modal-img-btn"
                                />
                                {selectedToken.symbol.toUpperCase()}
                              </>
                            ) : (
                              translate("banner.actions.selectToken")
                            )}
                            <i className="fa fa-angle-down"></i>
                          </div>
                        </button>{" "}
                        <div
                          style={{
                            fontSize: "small",
                            // width: "6rem",
                            whiteSpace: "nowrap",
                            // overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {selectedToken
                            ? `${
                                balance !== null
                                  ? `${balance} ${selectedToken.symbol?.toUpperCase()}`
                                  : ""
                              }`
                            : ""}
                        </div>
                      </div>
                      <div
                        className="modal "
                        id="selecttoken2"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                {translate("banner.actions.selectToken")}
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <input
                                type="text"
                                name="userToken"
                                placeholder={translate("enterContractAddress")}
                                className="form-control search-input-box search-input-box-2 pt-2 pb-2 mb-2"
                                // value={selectToken}
                                onChange={selectHandler}
                                autoComplete="off"
                              />
                              {tokenData ? (
                                tokenData.map((suggestion, index) => (
                                  <div
                                    key={index}
                                    className="suggestion-item token-suggestions-data"
                                    data-bs-dismiss="modal"
                                    onClick={() =>
                                      selectTokenHandler(suggestion)
                                    }
                                  >
                                    <div className="suggestion-img">
                                      <img src={suggestion.imageLogo} alt="" />
                                    </div>
                                    <div className="model-data-flex">
                                      <div className="suggestion-name">
                                        {suggestion.name} (
                                        <strong>
                                          {suggestion.symbol.toUpperCase()}
                                        </strong>
                                        )
                                      </div>
                                      <div className="suggestion-sym contract-add-model">
                                        {suggestion.contractAddress}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="suggestion-item">
                                  {translate("noResultFound")}
                                </div>
                              )}
                            </div>
                            {/* <div className="modal-footer get-started-btn ok-btn">
                              <button
                                type="button"
                                className="btn text-white "
                                data-bs-dismiss="modal"
                              >
                                Ok
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </>
            ) : (
              <>
                {" "}
                <div className="swap-value mb-1">
                  <div className="grey-color">
                    {translate("banner.actions.sell")}
                  </div>
                  <div className="enter-val grey-color">
                    <div className="grey-color">
                      <input
                        type="text"
                        placeholder="0"
                        id="number-input"
                        value={estimateTokenValue}
                        className="text-white "
                        onChange={handleTokenInput}
                        autoComplete="off"
                      />
                    </div>
                    <div className="select-token-btn">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-modal-backdrop"
                          data-bs-toggle="modal"
                          data-bs-target="#selecttoken3"
                        >
                          <div
                            className="flex-class text-white "
                            style={{ textWrap: "nowrap" }}
                          >
                            {selectedToken ? (
                              <>
                                <img
                                  src={selectedToken.imageLogo}
                                  alt=""
                                  className="modal-img-btn"
                                />
                                {selectedToken.symbol.toUpperCase()}
                              </>
                            ) : (
                              translate("banner.actions.selectToken")
                            )}
                            <i className="fa fa-angle-down "></i>
                          </div>
                        </button>
                        <div
                          style={{
                            fontSize: "small",
                            // width: "6rem",
                            whiteSpace: "nowrap",
                            // overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {" "}
                          {/* {ethwBalance !== null ? (
                            <>{ethwBalance} ETHW </>
                          ) : (
                            <></>
                          )} */}
                          {selectedToken
                            ? `${
                                balance !== null
                                  ? `${balance} ${selectedToken.symbol?.toUpperCase()}`
                                  : ""
                              }`
                            : ""}
                        </div>
                      </div>
                      <div
                        className="modal "
                        id="selecttoken3"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                {translate("banner.actions.selectToken")}
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <input
                                type="text"
                                name="userToken"
                                placeholder={translate("enterContractAddress")}
                                className="form-control search-input-box search-input-box-2 pt-2 pb-2 mb-2"
                                // value={selectToken}
                                onChange={selectHandler}
                                autoComplete="off"
                              />
                              {tokenData ? (
                                tokenData.map((suggestion, index) => (
                                  <div
                                    key={index}
                                    className="suggestion-item token-suggestions-data"
                                    onClick={() =>
                                      selectTokenHandler(suggestion)
                                    }
                                    data-bs-dismiss="modal"
                                  >
                                    <div className="suggestion-img">
                                      <img src={suggestion.imageLogo} alt="" />
                                    </div>
                                    <div className="model-data-flex">
                                      <div className="suggestion-name">
                                        {suggestion.name} (
                                        <strong>
                                          {suggestion.symbol.toUpperCase()}
                                        </strong>
                                        )
                                      </div>
                                      <div className="suggestion-sym contract-add-model">
                                        {suggestion.contractAddress}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="suggestion-item">
                                  {translate("noResultFound")}
                                </div>
                              )}
                            </div>
                            {/* <div className="modal-footer get-started-btn ok-btn">
                              <button
                                type="button"
                                className="btn text-white "
                                data-bs-dismiss="modal"
                              >
                                Ok
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={swapDivs}
                >
                  <i
                    className="fa fa-arrow-down "
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      backgroundColor: " rgb(32, 32, 32)",
                      padding: "10px",
                      position: "absolute",
                      borderRadius: "10px",
                    }}
                  ></i>
                </div>{" "}
                <div className="swap-value mt-1">
                  <div className="grey-color">
                    {translate("banner.actions.buy")}
                  </div>
                  <div className="enter-val grey-color">
                    <div className="grey-color">
                      <input
                        type="text"
                        value={estimateETHWValue}
                        onChange={handleETHWInput}
                        placeholder="0"
                        id="number-input"
                        className="text-white "
                        autoComplete="off"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        flexDirection: "column",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-modal-backdrop"
                        // data-bs-toggle="modal"
                        // data-bs-target="#selecttoken4"
                      >
                        <div className="flex-class text-white ">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1733220306/B870CB730078368E_tedoei.webp"
                            alt=""
                            className="modal-img-btn"
                          />
                          ETHW
                          {/* <i className="fa fa-angle-down grey-clr"></i> */}
                        </div>
                      </button>
                      <div style={{ fontSize: "small", width: "7rem" }}>
                        {ethwBalance !== null ? (
                          <>{ethwBalance} ETHW </>
                        ) : (
                          <></>
                        )}

                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </div>
                      {/* <div
                        className="modal "
                        id="selecttoken4"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                {translate("banner.actions.selectToken")}
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              aboriosam aperiam nobis reprehenderit deserunt
                              quibusdam facere saepe tenetur mollitia. Lorem,
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Quia nihil magnam eos veniam in possimus odit
                              sequi non eius, illum laboriosam aperiam nobis
                              reprehenderit deserunt quibusdam facere saepe
                              tenetur mollitia.
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" className="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div>
                    <span className="grey-color">$0</span>
                  </div> */}
                </div>
              </>
            )}
            {showModal && (
              // <div className="modal show d-block" tabIndex="-1" role="dialog">
              <div
                class="modal modal-backcss show d-block"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog   modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content text-center">
                    <div className="modal-header">
                      <h5 className="modal-title">Swap Successful</h5>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={closeModal}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p style={{ marginBottom: "0" }}>
                        Tokens swapped successfully!
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        style={{
                          backgroundColor: "#00a8e8",
                          color: "#fff",
                          fontWeight: "500",
                        }}
                        type="button"
                        className="btn"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="get-started-btn " onClick={swapHandler}>
              <button className="text-white ">
                {loading ? <Loading /> : translate("banner.actions.getStarted")}
              </button>
            </div>
          </div>{" "}
          {/* <span className="bg-none grey-clr last-span-div">
            <div>1ETHW = $0.0003</div>
            <div>
              <i className="fa fa-gas-pump me-1 fa-xs"></i>0.30ETH
            </div>
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
