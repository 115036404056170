import * as API from "../../../Utils/Services/api";
import React, { useEffect, useRef, useState } from "react";
import translation from "../../Translations/translation.json";
import { NavLink } from "react-router-dom";
import ethImg from "../../../Assets/tinified/token-5.png";
import "./Buy.css";
import { useLanguage } from "../../LanguageContext";
import { toast } from "react-toastify";
export const logos = [
  {
    name: "POL",
    link: "/",
  },
  {
    name: "SHIB",
    link: "/",
  },
  {
    name: "USDP",
    link: "/",
  },
  {
    name: "SHIELDS",
    link: "/",
  },
  {
    name: "CRV",
    link: "/",
  },
  {
    name: "TUBBY",
    link: "/",
  },
  {
    name: "WBTC",
    link: "/",
  },
  {
    name: "ETH",
    link: "/",
  },
  {
    name: "USDC",
    link: "/",
  },
  {
    name: "MANA",
    link: "/",
  },
];
const Buy = () => {
  const [value, setValue] = useState();
  const [tokenData, setTokenData] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [category, setCategory] = useState("");
  const handleChange = (e) => {
    setValue(e.target.value.replace(/[^0-9]/g, ""));
  };
  const { toggleLanguage, language } = useLanguage();
  const [estimateETHWValue, setEstimateETHWValue] = useState("");
  const [selectedToken, setSelectedToken] = useState(null);
  const debounceTimeout = useRef(null);
  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };

  const getAllTokenDetail = async (filter = "") => {
    try {
      const data = {
        page: 1,
        size: 10,
        filter,
      };
      const resp = await API.GetAllToken(data);
      setTokenData(resp.data.allToken);
    } catch (err) {
      toast.error(err.response.data.message);
      setTokenData(null);
    }
  };
  const selectTokenHandler = (data) => {
    setSelectedToken(data);
  };
  useEffect(() => {
    getAllTokenDetail();
  }, []);
  const handleETHWInput = (e) => {
    // setValue(e.target.value.replace(/[^0-9]/g, ""));
    const value = e.target.value !== "" ? e.target.value : "0";
    setEstimateETHWValue(e.target.value);
    if (!selectedToken) return;

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  };
  const selectHandler = (e) => {
    const filter = e.target.value;
    // Clear previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      getAllTokenDetail(filter);
    }, 1000);
  };

  return (
    <div className="section-bg-pages">
      <div className="banner-bg ">
        <div className="flex-bannerimg">
          {logos.map((logo, index) => (
            <NavLink to={logo.link} key={index}>
              <div className={`logo-img logo-${index + 1}`}>
                <div className="logo-info">{logo.name}</div>
              </div>
            </NavLink>
          ))}
        </div>
        <div className="main-div">
          <div className="div-table">
            <div className="table-swap">
              <div className="swap-value send-swap-val buy-swap-value mb-1 buy-swap-value-div">
                <div className="grey-color">
                  {translate("transaction.youAreBuying")}
                </div>
                <div className="enter-val grey-color">
                  <div className="grey-color">
                    <input
                      type="text"
                      value={value}
                      onChange={handleChange}
                      min={0}
                      placeholder="$0"
                      id="number-input-send"
                      className="text-white "
                    />
                  </div>{" "}
                  <div className="button-div-send">
                    <button
                      type="button"
                      className="btn btn-modal-backdrop"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      {" "}
                      <div
                        className="flex-class text-white "
                        style={{ textWrap: "nowrap" }}
                      >
                        {selectedToken ? (
                          <>
                            <img
                              src={selectedToken.imageLogo}
                              alt=""
                              className="modal-img-btn"
                            />
                            {selectedToken.symbol.toUpperCase()}
                          </>
                        ) : (
                          translate("banner.actions.selectToken")
                        )}
                        <i className="fa fa-angle-down "></i>
                      </div>
                    </button>

                    <div
                      className="modal "
                      id="exampleModal"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable text-white">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1
                              className="modal-title fs-5"
                              id="exampleModalLabel"
                            >
                              {translate("banner.actions.selectToken")}
                            </h1>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <input
                              type="text"
                              name="userToken"
                              placeholder={translate("enterContractAddress")}
                              className="form-control search-input-box search-input-box-2 pt-2 pb-2 mb-2"
                              // value={selectToken}
                              onChange={selectHandler}
                              autoComplete="off"
                            />
                            {tokenData ? (
                              tokenData.map((suggestion, index) => (
                                <div
                                  key={index}
                                  className="suggestion-item token-suggestions-data"
                                  data-bs-dismiss="modal"
                                  onClick={() => selectTokenHandler(suggestion)}
                                >
                                  <div className="suggestion-img">
                                    <img src={suggestion.imageLogo} alt="" />
                                  </div>
                                  <div
                                    className="model-data-flex"
                                    style={{ textAlign: "left" }}
                                  >
                                    <div className="suggestion-name">
                                      {suggestion.name} (
                                      <strong>
                                        {suggestion.symbol.toUpperCase()}
                                      </strong>
                                      )
                                    </div>
                                    <div className="suggestion-sym contract-add-model">
                                      {suggestion.contractAddress}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="suggestion-item">
                                {translate("noResultFound")}
                              </div>
                            )}
                          </div>
                          {/* <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="button" className="btn btn-primary">
                              Save changes
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="get-started-btn ">
                <button className="text-white ">
                  {" "}
                  {translate("banner.actions.getStarted")}{" "}
                </button>
              </div>
              {/* <h1>{translate("comingSoon")}</h1> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buy;
