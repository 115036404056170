import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import translation from "../../Translations/translation.json";
import "./Migrate.css";
import { useLanguage } from "../../LanguageContext";

const Migrate = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigates to the previous page
  };
  const { toggleLanguage, language } = useLanguage();

  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  return (
    <div className="section-bg-pages section-bg view-positions-pg view-positions-pg-migrate">
      {" "}
      <div className="gradient-bg-circle-2"> </div>
      <div className="gradient-bg-circle"></div>
      <div className="container">
        <div className="page-width-vp">
          <div className="migrate-pg">
            <div className="migrate-pg-heading">
              <div>
                <i onClick={handleGoBack} className="fa fa-arrow-left"></i>
              </div>
              <div> {translate("migrateLiquidity.heading")}</div>
              <div>
                <i
                  className="fa fa-info-circle"
                  data-tip={`${translate("migrateLiquidity.dataTip")}`}
                ></i>
                <Tooltip place="top" effect="solid" />
              </div>
            </div>
            <div className="migrate-pg-body">
              {translate("migrateLiquidity.description")}
              <div className="v2-list">
                {" "}
                {translate("migrateLiquidity.noLiquidity")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Migrate;
