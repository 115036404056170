import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Contract } from "ethers";
import { toast } from "react-toastify";
import { poolContract } from "../../../Ethereum/Constant/addresses";
import { poolABI, ERC20ABI } from "../../../Ethereum/Constant/abis";
import { contractInstance, signer } from "../../../Ethereum/Connect/config";
import Loading from "../../Loading/Loading";
import { useLanguage } from "../../LanguageContext";
import translation from "../../Translations/translation.json";

const RemoveLiquidity = () => {
  const navigate = useNavigate();
  const [liquidityAddress, setLiquidityAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const removeHandler = async () => {
    try {
      setLoading(true);
      const contract = await contractInstance(liquidityAddress, poolABI);
      const liquidityTokenAddress = await contract.liquidityToken();
      const userSigner = await signer();
      const liquidityToken = new Contract(
        liquidityTokenAddress,
        ERC20ABI,
        userSigner
      );
      const lpBalance = await liquidityToken.balanceOf(userSigner);

      toast.info("Transaction sent! Waiting for confirmation...");
      const tx = await contract.removeLiquidity(lpBalance);
      await tx.wait();
      toast.success("Liquidity pool removed!");
      // console.log(
      //   "ETHW, Token",
      //   await contract.ethwReserve(),
      //   await contract.tokenReserve()
      // );
      setLoading(false);
    } catch (err) {
      console.log("ERROR::", err);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const { toggleLanguage, language } = useLanguage();

  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  return (
    <div className=" view-positions-pg add-liquidity-pg remove-liquidity-page">
      <div className="gradient-bg-circle-2"> </div>
      <div className="gradient-bg-circle"></div>{" "}
      <div className="container">
        <div className="page-width-vp">
          <div className="createpositons-pg remove-liquidity-pg ">
            <div className="migrate-pg-heading">
              <div>
                <i onClick={handleGoBack} className="fa fa-arrow-left"></i>
              </div>
              <div className="add-liquidity-heading">
                {translate("removeLiquidity.heading")}
              </div>
              <div></div>
            </div>
            <div className="migrate-pg-body">
              <p>{translate("removeLiquidity.enterAddress")}</p>
              <div className="div-add-positons-btn">
                <div className="add-postions-form-btn selected-token-btn">
                  <input
                    type="text"
                    name="userToken"
                    className="form-control search-input-box search-input-box-liqui pt-2 pb-2"
                    value={liquidityAddress}
                    placeholder={translate("removeLiquidity.enterAddress")}
                    onChange={(e) => setLiquidityAddress(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="get-started-btn mt-4 remove-btn">
                <button className="text-white " onClick={removeHandler}>
                  {loading ? <Loading /> : translate("removeLiquidity.remove")}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveLiquidity;
