import { NavLink } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import translation from "../Translations/translation.json";

const FooterSub = () => {
  const { toggleLanguage, language } = useLanguage();

  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  return (
    <div className="section-bg">
      <div className="container">
        <div className="footer-pg footer-sub-pg">
          {" "}
          {/* <div style={{ textAlign: "center" }}>
            © All rights reserved 2024 - PoWDex.io
          </div> */}
          <div className="footer-flex bg-none ">
            {" "}
            <NavLink>
              <i className="fa-brands fa-x-twitter fa-lg text-white"></i>
            </NavLink>
            <div style={{ color: "grey" }}>© {translate("rights")}</div>
            {/* <div>
              <span className="me-2">Trademark Policy</span>
              <span> Privacy Policy</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSub;
