import { useLanguage } from "../../LanguageContext";
import translation from "../../Translations/translation.json";
import "./Faq.css";

const Faq = () => {
  const { toggleLanguage, language } = useLanguage();

  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };
  return (
    <div className="faq-pg">
      <div className="faq-page">
        <div className="container ">
          <div className="row">
            <div className="about-howto-create-token ">
              <h4>{translate("generalFaq.heading")}</h4>
            </div>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {/* 1 */}
              {translate("generalFaq.questions")?.map((que, i) => (
                <div className="accordion-item" key={i}>
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-coll-${i}`}
                      aria-expanded="false"
                      aria-controls={`flush-coll-${i}`}
                    >
                      {que.question}
                    </button>
                  </h2>
                  <div
                    id={`flush-coll-${i}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {que.answer.includes("\n") ? (
                        <ul>
                          {que.answer.split("\n").map((indi, i) => (
                            <li key={i}>{indi}</li>
                          ))}
                        </ul>
                      ) : (
                        <ul>
                          <li>{que.answer}</li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {/* 2 */}
              {/* <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-coll-2"
                    aria-expanded="false"
                    aria-controls="flush-coll-2"
                  >
                    What makes PowDex different from other DEXs?
                  </button>
                </h2>
                <div
                  id="flush-coll-2"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    PowDex is optimized for the ETHW blockchain, offering low
                    transaction fees and high scalability. It is tailored for
                    ETHW and ERC-20 token liquidity pairs, empowering users in
                    the ETHW ecosystem to trade seamlessly.
                  </div>
                </div>
              </div> */}
              {/* 3 */}
              {/* <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-coll-3"
                    aria-expanded="false"
                    aria-controls="flush-coll-3"
                  >
                    How does PowDex work?
                  </button>
                </h2>
                <div
                  id="flush-coll-3"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    PowDex leverages smart contracts to facilitate token swaps
                    and liquidity provision. It operates through liquidity pools
                    where prices are determined algorithmically based on token
                    reserves.
                  </div>
                </div>
              </div> */}
              {/* 4 */}
              {/* <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-coll-4"
                    aria-expanded="false"
                    aria-controls="flush-coll-4"
                  >
                    Why use PowDex instead of centralized exchanges?
                  </button>
                </h2>
                <div
                  id="flush-coll-4"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        Non-Custodial: Users have full control over their funds.
                      </li>
                      <li>
                        Transparent: All trades and liquidity data are publicly
                        verifiable on the ETHW blockchain.
                      </li>

                      <li>
                        Decentralized: PowDex eliminates the need for
                        intermediaries, reducing risks of hacks and censorship.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>{" "} */}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-12">
              <div className="about-howto-create-token ">
                <h4>{translate("powDexFaq.heading")}</h4>
              </div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* 1 */}
                {translate("powDexFaq.questions")?.map((item, i) => (
                  <div className="accordion-item" key={i}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#pow${i}`}
                        aria-expanded="false"
                        aria-controls={`pow${i}`}
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={`pow${i}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {item.answer.includes("\n") ? (
                          <ul>
                            {item.answer.split("\n").map((indi, i) => (
                              <li key={i}>{indi}</li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            <li>{item.answer}</li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* 2 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pow-2"
                      aria-expanded="false"
                      aria-controls="pow-2"
                    >
                      Which wallets are compatible with PowDex?{" "}
                    </button>
                  </h2>
                  <div
                    id="pow-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li> PowDex supports wallets like MetaMask wallet.</li>{" "}
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* 3 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pow-3"
                      aria-expanded="false"
                      aria-controls="pow-3"
                    >
                      What tokens can I trade on PowDex?
                    </button>
                  </h2>
                  <div
                    id="pow-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          PowDex supports ETHW and any ERC-20 tokens deployed on
                          the ETHW blockchain.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* 4 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pow-4"
                      aria-expanded="false"
                      aria-controls="pow-4"
                    >
                      What fees are involved when using PowDex?
                    </button>
                  </h2>
                  <div
                    id="pow-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Users pay:
                      <ul>
                        <li>
                          Trading Fee: A small percentage of the trade,
                          distributed to liquidity providers.
                        </li>
                        <li>
                          Gas Fee: Transaction fees for the ETHW blockchain.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* 5 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pow-5"
                      aria-expanded="false"
                      aria-controls="pow-5"
                    >
                      Can I use PowDex on mobile devices?
                    </button>
                  </h2>
                  <div
                    id="pow-5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          {" "}
                          Yes, PowDex is mobile-friendly and can be accessed via
                          a browser or mobile wallets like MetaMask and Trust
                          Wallet.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>{" "} */}
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-lg-0 mt-md-4 mt-4">
              <div className="about-howto-create-token ">
                <h4>{translate("liquidityPoolFAQ.heading")}</h4>
              </div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* 1 */}
                {translate("liquidityPoolFAQ.questions")?.map((item, i) => (
                  <div className="accordion-item" key={i}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#liq-${i}`}
                        aria-expanded="false"
                        aria-controls={`liq-${i}`}
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={`liq-${i}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {item.answer.includes("\n") ? (
                          <ul>
                            {item.answer.split("\n").map((indi, i) => (
                              <li key={i}>{indi}</li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            <li>{item.answer}</li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* 2 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#liq-2"
                      aria-expanded="false"
                      aria-controls="liq-2"
                    >
                      How can I add liquidity on PowDex?
                    </button>
                  </h2>
                  <div
                    id="liq-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>Navigate to the “Liquidity” section.</li>
                        <li>
                          Choose a token pair (e.g., ETHW and an ERC-20 token).
                        </li>

                        <li>
                          Provide equal values of both tokens and confirm the
                          transaction.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* 3 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#liq-3"
                      aria-expanded="false"
                      aria-controls="liq-3"
                    >
                      What rewards do liquidity providers earn?
                    </button>
                  </h2>
                  <div
                    id="liq-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          Liquidity providers earn a share of transaction fees
                          proportional to their contribution to the pool.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* 4 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#liq-4"
                      aria-expanded="false"
                      aria-controls="liq-4"
                    >
                      Can I remove my liquidity anytime?
                    </button>
                  </h2>
                  <div
                    id="liq-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          {" "}
                          Yes, you can withdraw your liquidity along with any
                          accumulated fees at any time by removing your
                          liquidity from the pool.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* 5 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#liq-5"
                      aria-expanded="false"
                      aria-controls="liq-5"
                    >
                      Why is ETHW required in all pools?
                    </button>
                  </h2>
                  <div
                    id="liq-5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          ETHW serves as the base asset, ensuring consistent
                          pricing and ease of interoperability across all token
                          pairs.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* governance  */}
          <div className="row mt-4">
            <div className="col-lg-6 col-12">
              <div className="about-howto-create-token ">
                <h4>{translate("governanveFaq.heading")}</h4>
              </div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* 1 */}
                {translate("governanveFaq.questions")?.map((item, i) => (
                  <div className="accordion-item" key={i}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#gov-${i}`}
                        aria-expanded="false"
                        aria-controls={`gov-${i}`}
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={`gov-${i}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {item.answer.includes("\n") ? (
                          <ul>
                            {item.answer.split("\n").map((indi, i) => (
                              <li key={i}>{indi}</li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            <li>{item.answer}</li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* 2 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#gov-2"
                      aria-expanded="false"
                      aria-controls="gov-2"
                    >
                      What is PowDex governance?
                    </button>
                  </h2>
                  <div
                    id="gov-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          {" "}
                          PowDex governance enables token holders to propose and
                          vote on protocol upgrades, fee adjustments, and new
                          feature implementations.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-lg-0 mt-md-4 mt-4">
              <div className="about-howto-create-token ">
                <h4>{translate("troubleShootFaq.heading")}</h4>
              </div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* 1 */}
                {translate("troubleShootFaq.questions")?.map((item, i) => (
                  <div className="accordion-item" key={i}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#tro-${i}`}
                        aria-expanded="false"
                        aria-controls={`tro-${i}`}
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={`tro-${i}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {item.answer.includes("\n") ? (
                          <ul>
                            {item.answer.split("\n").map((indi, i) => (
                              <li key={i}>{indi}</li>
                            ))}
                          </ul>
                        ) : (
                          <ul>
                            <li>{item.answer}</li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* 2 */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#tro-2"
                      aria-expanded="false"
                      aria-controls="tro-2"
                    >
                      Why does my token swap show "Insufficient Liquidity"?
                    </button>
                  </h2>
                  <div
                    id="tro-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          This occurs when the selected pool lacks enough tokens
                          for the trade. You can either reduce the trade size or
                          choose a different pool.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
